import React from 'react'
import Background from '../images/background_img.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faBed,
  faShower,
  faWaterLadder,
  faHouse,
  faTree,
  faPaw,
} from '@fortawesome/free-solid-svg-icons'
import Mapbox from '../components/Mapbox'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useRef } from 'react'

const Home = () => {
  const { t } = useTranslation()
  const nextSectionRef = useRef(null)

  return (
    <>
      <Helmet>
        <title>Les Grottes : house to rent in the South of France</title>
        <meta
          name="description"
          content="This large and comfortable house is available to rent in the region of Luberon, France."
        />
      </Helmet>
      <div className="bigContainer">
        <div className="header">
          <img
            src={Background}
            alt="maisons aux volets bleus"
            className="backgroundImg w-full h-screen max-h-fit object-cover brightness-50 relative"
          />
          <div className="textLogo absolute left-1/2 top-1/4 transform -translate-x-1/2 font-logo lg:text-9xl text-6xl text-white pt-10">
            Les Grottes
          </div>
          <div className="subtitle absolute left-1/2 top-2/4 transform -translate-x-1/2 text-provence font-text text-xl text-center pt-5">
            {t('slogan')}
          </div>
          <div className="icon text-white text-2xl absolute left-1/2 top-3/4 pt-20 animate-bounce -translate-x-1/2 cursor-pointer">
            <FontAwesomeIcon
              icon={faArrowDown}
              onClick={() =>
                nextSectionRef.current.scrollIntoView({ behavior: 'smooth' })
              }
            />
          </div>
        </div>

        <section ref={nextSectionRef}>
          <div className="iconsPart">
            <div className="Iconscontainer grid md:grid-rows-3 grid-rows-4 grid-cols-9 gap-5 pb-10">
              <div className="bedIcon row-start-2 col-start-3 lg:col-start-3 lg:col-span-1 col-span-2 md:col-span-2 md:col-start-3 relative mt-7">
                <FontAwesomeIcon
                  icon={faBed}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  {t('sleep-icon')}
                </p>
              </div>
              <div className="showerIcon row-start-2 lg:col-start-5 col-start-6 col-span-2 lg:col-span-1 relative mt-5">
                <FontAwesomeIcon
                  icon={faShower}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  {t('bathroom-icon')}
                </p>
              </div>
              <div className="poolIcon lg:row-start-2 row-start-3 lg:col-start-7 col-start-3 col-span-2 lg:col-span-1 relative mt-5">
                <FontAwesomeIcon
                  icon={faWaterLadder}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  {t('swimming-icon')}
                </p>
              </div>
              <div className="houseIcon row-start-3 lg:col-start-3 col-start-6 col-span-2 lg:col-span-1 relative mt-5">
                <FontAwesomeIcon
                  icon={faHouse}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  ± 340 m²
                </p>
              </div>
              <div className="forestIcon lg:row-start-3 row-start-4 lg:col-start-5 col-start-3 col-span-2 lg:col-span-1 relative mt-5">
                <FontAwesomeIcon
                  icon={faTree}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  {t('forest-icon')}
                </p>
              </div>
              <div className="pawIcon lg:row-start-3 row-start-4 lg:col-start-7 col-start-6 col-span-2 lg:col-span-1 relative mt-5">
                <FontAwesomeIcon
                  icon={faPaw}
                  className="text-3xl text-provence absolute left-1/2 transform -translate-x-1/2"
                />
                <p className="text-center pt-12 font-text text-sm md:text-lg">
                  {t('pet-icon')}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="bookNow w-full py-10">
          <div className="flex flex-col items-center">
            <a
              className="bg-provence text-white font-bold font-text py-4 px-8 rounded-2xl hover:bg-blue-400"
              href="/photos"
            >
              {t('photos')}
            </a>
          </div>
        </div>

        <div className="description grid grid-row-1 grid-cols-11 mt-5">
          <p className="row-start-1 col-start-3 col-end-10 text-center font-text leading-7">
            {t('text')}
          </p>
        </div>

        <div className="">
          <div className="">
            <Mapbox />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
