import { useState, useEffect } from 'react'
import logo from '../images/logo_black.png'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
// import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'
import { useLocation } from 'react-router-dom'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => {
    setNav(!nav)
  }
  const { t } = useTranslation()

  const location = useLocation()
  const [activeItem, setActiveItem] = useState('info')

  useEffect(() => {
    const pathname = location.pathname
    if (pathname === '/') {
      setActiveItem('info')
    } else if (pathname === '/sivergues') {
      setActiveItem('surroundings')
    } else if (pathname === '/photos') {
      setActiveItem('photos')
    } else if (pathname === '/rent') {
      setActiveItem('rent')
    } else if (pathname === '/contact') {
      setActiveItem('contact')
    }
  }, [location.pathname])

  return (
    <div className="border-[1px] w-full fixed top-0 left-0 z-10 bg-white z-1">
      <div className="grid grid-cols-3 gap-4">
        <span className="text-3xl text-gray-800 mr-1 ml-3">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              className="h-[60px] w-[120px] relative"
            />
          </a>
        </span>
        <div className="md:flex items-center justify-center space-between bg-white py-4 md:px-10 px-7">
          <div className="cursor-pointer flex items-center font-title text-gray-800"></div>
          <div
            onClick={handleClick}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            {!nav ? (
              <Bars3Icon className="w-5" />
            ) : (
              <XMarkIcon className="w-5" />
            )}
          </div>
          <ul
            className={`bg-white space-x-20 pb-12 absolute xsm:mt-[-25px] sm:mt-[-20px] md:mt-[5px] md:flex md:space-x-8 md:ml-[40px] md:items-center md:pb-0 md:static md:z-auto z[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in lg:space-x-20 ${
              nav
                ? 'top-20 opacity-100'
                : 'top-[-490px] md:opacity-100 opacity-0'
            }`}
          >
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <p className="text-gray-800 duration-500 md:hidden">Menu</p>
            </li>
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <a
                href="/"
                className={`text-gray-800 hover:text-provence duration-500 ${
                  activeItem === 'info' && 'text-provence font-bold'
                }`}
                onClick={() => setActiveItem('info')}
              >
                {t('info')}
              </a>
            </li>
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <a
                href="/sivergues"
                className={`text-gray-800 hover:text-provence duration-500 ${
                  activeItem === 'surroundings' ? 'text-provence font-bold' : ''
                } `}
                onClick={() => setActiveItem('surroundings')}
              >
                {t('surroundings')}
              </a>
            </li>
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <a
                href="/photos"
                className={`text-gray-800 hover:text-provence duration-500 ${
                  activeItem === 'photos' ? 'text-provence font-bold' : ''
                }`}
                onClick={() => setActiveItem('photos')}
              >
                {t('photos')}
              </a>
            </li>

            {/* <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <a
                href="/rent"
                className={`text-gray-800 hover:text-provence duration-500 ${
                  activeItem === 'rent' ? 'text-provence font-bold' : ''
                }`}
                onClick={() => setActiveItem('rent')}
              >
                {t('rent')}
              </a>
            </li> */}
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <a
                href="/contact"
                className={`text-gray-800 hover:text-provence duration-500 ${
                  activeItem === 'contact' ? 'text-provence font-bold' : ''
                }`}
                onClick={() => setActiveItem('contact')}
              >
                {t('contact')}
              </a>
            </li>
            <li className="md:ml-8 text-xl md:my-0 my-7 font-title">
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Navbar
